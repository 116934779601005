.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's on top of other elements */
  backdrop-filter: blur(
    5px
  ); /* Apply a blur effect to the background content */
}

.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  background-color: #fbfbfd;
}

.app-list-style-none {
  list-style: none;
}

.app-header {
  background-image: url("/public/team-public-page-header.jpg");
  background-size: cover;
  color: #fbfbfd;
  text-align: center;
  padding: 10px;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.app-description {
  display: flex;
  flex-direction: column;
  position: relative;
  background-image: url("/public/background-blue-gradient.jpg") !important;
  background-size: cover;
  color: #fbfbfd;
  padding: 50px 0px 120px 60px;
}

.app-description-title {
  font-size: 44px;
  font-weight: 700;
  line-height: 58px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 24px;
  margin-right: 40px;
}

.app-description-subtitle {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 8px;
}

.app-description-button-yellow {
  background-color: rgba(254, 206, 2, .75) !important;
  color: #1d1d1f !important;
  height: 60px;
  margin-top: 30px;
}

.image-container {
  margin-top: 20px; 
  margin-left: auto; 
}

.content-container {
  margin-bottom: -260px;
  max-width: 40%;
}

.mobile-results-image {
  position: relative;
  width: 25%;
  height: 100%; 
  margin-right: -100px;
  margin-bottom: -90px;
  z-index: 20;
  background: rgba(255, 255, 255, 0.5);
  padding: 12px;
  backdrop-filter: blur(4px);
  border-radius: 24px;
}

.results-image {
  position: relative;
  width: 75%; 
  height: auto; 
  margin-left: 30px;
  margin-top: -100px;
  z-index: 1;
  background: rgba(255, 255, 255, 0.5);
  padding: 12px;
  backdrop-filter: blur(4px);
  border-radius: 24px;
  max-width: fit-content;
}

.header-logo {
  width: 80px;
  height: 80px;
  position: absolute;
  top: 10px;
  left: 60px;
}

.header-buttons {
  position: absolute;
  top: 10px;
  right: 45px;
  padding: 15px;
}

.header-button-yellow {
  background-color: rgba(254, 206, 2, .75) !important;
  color: #1d1d1f !important;
}

.main-home {
  background-color: #fbfbfd;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: start;
  box-sizing: border-box;
}

.panel-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  gap: 25px;
}

.feature-panel {
  width: 100%;
  box-sizing: border-box;
}

.panel-container.is-visible {
  opacity: 1;
  transform: translateY(0);
}

.feature-panel-inner {
  width: 100%;
  padding: 20px;
  padding-top: 0;
  box-sizing: border-box;
  background-color: #fbfbfd !important;
  box-shadow: none !important;
}

.panel-content {
  display: flex;
  align-items: center; 
}

.feature-image-container {
  width: 100%; 
}

.feature-image {
  width: 25%; 
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  background: #fbfbfd;
  padding: 12px;
  backdrop-filter: blur(4px);
  border-radius: 32px;
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.08);
  max-width: 700px;
}

.feature-image2 {
  width: 75%; 
}

.feature-image3 {
  width: 100%; 
}

.feature-content {
  width: 90%; 
  padding-left: 20px; 
  max-width: 700px;
}

.feature-content h3 {
  margin-bottom: 10px; 
}

.app-footer {
  background-image: url("/public/team-public-page-header.jpg");
  background-size: cover;
  color: white;
  text-align: center;
  padding: 1em;
  transition: transform 0.3s ease; 
}

.app-footer.fixed-bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  transform: translateY(0);
}

.panel-gutter {
  height: 10px;
  background-color: white !important;
  width: 300%;
  margin: 0;
  padding: 0;
  margin-left: -500px;
}

.reveal {
  position: relative;
  opacity: 0;
}

.reveal.active {
  opacity: 1;
}
.active.fade-bottom {
  animation: fade-bottom 1s ease-in;
}
.active.fade-left {
  animation: fade-left .7s ease-in;
}
.active.fade-right {
  animation: fade-right .7s ease-in;
}
@keyframes fade-bottom {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-left {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-right {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.thermometer {
  display: flex;
  align-items: center;
}

.thermometer-item {
  width: 30px;
  height: 30px;
  border: 2px solid #105f93;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #105f93;
  position: relative;
  margin-right: 40px;
}

.thermometer-item::before {
  content: attr(data-number);
  width: 100%;
  height: 100%;
  background-color: #105f93;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  transform: scaleX(0);
  transition: transform 0.3s;
  color: #fbfbfd;
  display: flex;
  align-items: center;
  justify-content: center;
}

.thermometer-item.filled::before {
  transform: scaleX(1);
  left: 0;
  transform-origin: center;
}

.thermometer-item:not(:last-child)::after {
  content: '';
  position: absolute;
  width: 40px;
  height: 4px;
  background-color: #105f93;
  top: calc(50% - 2px);
  left: 107%;
}

.custom-calendar .p-datepicker-trigger {
  color: #105f93;
}

.no-border-input {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}