.p-icon {
  width: 20px;
  height: 20px;
}

.profile-hover {
  background: transparent;
}

.container {
  position: relative;
  cursor: pointer;
}

.container:hover .overlay {
  opacity: 1;
}

.overlay-content {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  font-weight: 600;
  font-size: 1.25rem;
  color: white;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.3s ease;
  background-color: #00000079;
}

.Toastify__toast {
  opacity: 0.9;
}

.Toastify__toast-theme--colored.Toastify__toast--info {
  background: #e9e9ff;
  color: #696cff;
}
.Toastify__toast-theme--colored.Toastify__toast--success {
  background: #e4f8f0 !important;
  color: #1ea97c !important;
}
.Toastify__toast-theme--colored.Toastify__toast--warning {
  background: #fff2e2 !important;
  color: #cc8925 !important;
}
.Toastify__toast-theme--colored.Toastify__toast--error {
  background: #ffe7e6 !important;
  color: #ff5757 !important;
}

.Toastify__progress-bar--info {
  background-color: rgba(105, 108, 255, 0.9) !important;
}
.Toastify__progress-bar--success {
  background-color: rgb(30, 169, 124, 0.9) !important;
}
.Toastify__progress-bar--warning {
  background-color: rgba(204, 137, 37, 0.9) !important;
}
.Toastify__progress-bar--error {
  background-color: rgba(255, 87, 87, 0.9) !important;
}
